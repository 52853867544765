.container{
    display: flex;
    flex-direction: column;
    border: 1px solid lightgrey;
    row-gap: 10px;
    align-items: center;
    flex-basis: 30%;
}
.title{
    font-family: "Open Sans Regular Bold";
    font-size: 12px;
    text-align: center;
}
.content{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 0 20px 20px 20px;
}
.advantage{
    font-family: "Open Sans Light";
    font-size: 11px;
    position: relative;
    padding-left: 10px;
}
.advantage::before{
    content: '';
    width: 3px;
    height: 3px;
    display: flex;
    position: absolute;
    top: 5px;
    background-color: black;
    border-radius: 50%;
    left: 0;
}
.slide{
    height: 200px;
    object-fit: cover;
    object-position: center;
}
@media (max-width:800px) {
    .slide{
        height: 400px;
    }
    .title{
        font-size: 17px;
    }
    .advantage{
        font-size: 14px;
    }
}
@media (max-width:425px) {
    .slide{
        height: 200px;
    }
}