.container {
  display: flex;
  background-color: white;
}
@media (max-width: 800px) {
  .container.row,
  .container.reverse {
    flex-direction: column-reverse;
  }
}

.reverse {
  flex-direction: row-reverse;
}
.row {
  flex-direction: row;
}
.title {
  font-family: 'Open Sans Regular Bold';
  font-size: 12px;
  padding: 0 70px;
  text-align: center;
}
.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  justify-content: center;
}
.content {
  flex-basis: 50%;
}
.text {
  font-family: 'Open Sans Light';
  font-size: 11px;
  position: relative;
  line-height: 1.857;
}
@media (max-width: 800px) {
  .title {
    font-size: 15px;
  }
  .text {
    font-size: 13px;
  }
}
