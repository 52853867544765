.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: #000000e6;
  justify-content: space-between;
  z-index: 200;
  padding: 50px 10px;
  align-items: center;
}
.list {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  align-items: center;
}
.item {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Open Sans Regular Bold';
}
.item:hover {
  opacity: 0.5;
}
.hide {
  top: -100vh;
  height: 0;
}
