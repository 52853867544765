.container{
    display: flex;
    padding: 20px calc((100% - 1200px)/2);
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}
@media (max-width:1240px) {
    .container{
        padding: 20px calc((100% - 1000px)/2);
    }
}
@media (max-width:1024px) {
    .container{
        padding: 20px calc((100% - 768px)/2);
    }
}
@media (max-width:800px) {
    .container{
        padding: 20px;
        flex-direction: column;
    }
}