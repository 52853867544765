* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: none;
  border: none;
  color: black;
  max-width: 2560px;
  font-family: Helvetica;
  transition: all 0.4s ease 0s;
  outline: none;
  scroll-behavior: smooth;
}
.container {
  display: flex;
  flex-direction: column;
  max-width: 2560px;
  margin: auto;
  background-color: white;
  margin-top: 120px;
}
@media (max-width: 768px) {
  .container {
    margin-top: 90px;
  }
}
@font-face {
  font-family: 'Open Sans Regular Bold';
  src: local('OpenSansRegularBold'),
    url('assets/fonts/Open Sans /OpenSans-Regular.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Roboto Condensed Bold';
  src: local('RobotoCondensedBold'),
    url('assets/fonts/Roboto Condensed /RobotoCondensed-Bold.ttf')
      format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Open Sans Light';
  src: local('OpenSansLight'),
    url('assets/fonts/Open Sans /OpenSans-Light.ttf') format('truetype');
  font-weight: normal;
}
