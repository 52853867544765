.container {
  display: flex;
  background-color: white;
  max-width: 1024px;
  margin: auto;
  text-transform: uppercase;
  padding: 20px;
  align-items: center;
  position: fixed;
  min-width: 100vw;
  max-height: 120px;
  top: 0;
  justify-content: center;
  width: 100%;
  z-index: 100;
  column-gap: 20px;
}
.list {
  display: flex;
  align-items: center;
  max-width: 1024px;
  width: 100%;
}

.logo {
  display: flex;
  flex-basis: 20%;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  min-width: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets//images/header-logo.png');
}
.logo:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .container {
    justify-content: space-between;
    padding: 5px 20px;
    max-height: 100px;
  }
  .logo {
    min-height: 80px;
    min-width: 80px;
  }
}
