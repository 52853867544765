.container {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  min-width: 30px;
  margin-right: 20px;
}
.container:hover {
  cursor: pointer;
  opacity: 0.5;
}
.line {
  display: flex;
  height: 2px;
  background-color: black;
  width: 100%;
}
