@media (max-width: 800px) {
  .print {
    min-height: 400px;
  }
}

@media (max-width: 425px) {
  .print {
    min-height: 200px;
  }
}
.print {
  flex-basis: 50%;
  overflow: hidden;
  display: flex;
  background-position: center;
  background-size: cover;
}
