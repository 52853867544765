.container{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 20px;
    background-color: white;
    min-width: 100%;
}
.phone{
    font-family: "Open Sans Regular Bold";
    font-size: 18px;
    text-align: center;
}
.phones{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.contacts{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}
.mail,
.location{
    font-family: "Open Sans Light";
    font-size: 11px;
}
.mail{
    text-decoration: none;
    border-bottom: 1px solid;
    padding-bottom: 2px;
    cursor: pointer;
}
@media (max-width:800px) {
    .mail,
    .location{
        font-size: 14px;
    }
}