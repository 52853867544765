.container{
    display: flex;
    row-gap: 20px;
    flex-basis: 40%;
    flex-grow: 1;
    flex-direction: column;
}
.content{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.title{
    font-family: "Open Sans Regular Bold";
    font-size: 12px;
    color: white;
}
.content{
    font-family: "Open Sans Light";
    font-size: 11px;
    color: white;
    margin-left: 50px;
}
.article{
    display: flex;
    column-gap: 20px;
    align-items: center;
}
@media (max-width:800px) {
    .title{
        font-size: 17px;
    }
    .content{
        font-size: 14px;
    }
}