.container{
    padding: 20px;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-name: showError;
    animation-duration: 0.7s;
    animation-iteration-count: unset;
}
@keyframes showError {
    0%{
        transform: translateX(200px);
    }
    50%{
        transform: translateX(-10px);
    }
    60%{
        transform: translateX(0);
    }
    70%{
        transform: translateX(-10px);
    }
    100%{
        transform: translateX(0);
    }
}
.message{
    display: flex;
    border-radius: 15px;
    background-color: white;
    padding: 20px;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    color: red;
    font-size: 14px;
    border: 1px solid lightgrey;
    flex-direction: column;
}
.cycle{
    border-radius: 50%;
    background-color: unset;
    border: 1px solid red;
    color: red;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
}