.container{
    display: flex;
    flex-direction: column;
    max-width: 1200px;
}
.border{
    border: 1px solid lightgrey;
}
.back{
    display: flex;
    padding: 20px calc((100% - 1200px)/2);
    justify-content: center;
    align-items: center;
    background-color: #fbfbfb;
}
@media (max-width:1240px) {
    .back{
        padding: 20px calc((100% - 1000px)/2);
    }
}
@media (max-width:1024px) {
    .back{
        padding: 20px calc((100% - 768px)/2);
    }
}
@media (max-width:800px) {
    .back{
        padding: 40px 20px;
    }
    .container{
        max-width: 100%;
    }
}
.print{
    background-position: center;
    background-size: cover;
    background-image: url('../../assets/images/history-back.webp');
}