.item {
  display: flex;
  font-family: 'Open Sans Regular Bold';
  letter-spacing: 1.2px;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  font-size: 9px;
  justify-content: center;
}
.item:hover {
  opacity: 0.5;
}
