.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px calc((100% - 1200px) / 2);
  row-gap: 10px;
  background-color: #0000004a;
}
.background {
  background-image: url('../../assets//images/article-background.jpg');
  background-size: cover;
  background-position: center;
}
h1.title {
  color: white;
  font-family: 'Roboto Condensed Bold';
  font-size: 40px;
}
h2.title {
  color: white;
  font-family: 'Open Sans Light';
  font-size: 15px;
}
.youtube {
  margin-top: 20px;
  width: 100%;
  max-width: 800px;
  min-height: 400px;
}
@media (max-width: 1024px) {
  .youtube {
    max-width: 700px;
  }
}
@media (max-width: 768px) {
  .youtube {
    max-width: unset;
  }
  .container {
    padding: 40px;
  }
}
@media (max-width: 425px) {
  .container {
    padding: 20px;
  }
  .youtube {
    min-height: 200px;
  }
}
